// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "app/medias";

// Dashboard
@import "app/dashboard";

// Floating labels
@import 'floating-labels';

// Forms
@import 'forms';

.user {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-user);
    }
}

.twitter {
    @extend %fa-icon;
    @extend .fab;

    &:before {
        content: fa-content($fa-var-twitter);
    }
}

.top-bar-color {
    background: $logoBgColor;
    color: $white;
}

.logo-bar {
    background-color: $logoBgColor;
    color: $white;
}

.sidebar-dark-primary {
    background: $primary;
}

#auth {
    background: $white;
}

#navigation {

    background: $topBarColor;
    color: $white;
    height: 65px;

    a {
        color: $white;
    }

    .opened {
        background: rgba(0, 0, 0, .3);
    }

    .navbar-nav {
        height: 100%;

        .user-menu{
            background-color: $logoBgColor;
        }
    }

    .nav-item {
        padding-top: 12px;
        padding-bottom: 8px;
    }
}

#brand-link {
    height: 65px;

    img {
        width: 100px;
        top:8px;
    }
}

.sidebar-mini.sidebar-collapse {
    #brand-link {
        img {
            width: 4rem;
            top:15px;
        }
    }
}

#main-sidebar {
    nav {
        li.active {
            .collapsed-link {
                border-color: transparent !important;
            }
        }

        a {
            border-radius: 0 !important;
        }

        a.text-white {
            border-left: 3px solid $topBarColor;
            background: rgba(255, 255, 255, .2);
        }
    }
}

.btn-primary{
    background-color: #3c8dbc;
    border-color: #367fa9;
    color: white;
}

.btn-danger{
    background-color: $bg-red;
}

.btn-success{
    background-color: $bg-green;
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
        max-width: 900px;
    }
}

.modal-body{
    font-size: 1.1em;
}

.modal-title {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.card.shadow{
    border-top: 3px solid #d2d6de;
}
